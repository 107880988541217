import 'magnific-popup';

jQuery( document ).ready( function ( $ ) {
	$( '.blocks-gallery-item>figure>a, .sme-gallery-block>div>a' ).magnificPopup( {
		type: 'image',
		gallery: {
			enabled: true
		},
		image: {
			titleSrc: function ( item ) {
				const alt = item.el.find( '>img' ).attr( 'alt' );
				const filename = item.el.attr( 'href' ).substring( item.el.attr( 'href' ).lastIndexOf( '/' ) + 1 );
				let rewritten_url = item.el.attr( 'href' ).replace( 'http://cdn.smehost.net', '' ).replace( 'https://cdn.smehost.net', '' );
				let html = '<a class="download-media" download="' + filename + '" href="' + rewritten_url + '">Download</a>';

				if ( alt ) {
					html += '<div class="mfp-credit">' + alt + '</div>';
				}
				return html;
			}
		}
	} );

	/*$( document ).on( 'click', '.download-media', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		const filename = href.substring( href.lastIndexOf( '/' ) + 1 );
		const a = document.createElement( 'a' );
		a.href = href;
		a.download = filename;
		a.click();
		a.remove();
	} );*/
} );
